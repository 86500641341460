import React from 'react';

export function ImgInsta5(){

    return (

        <>
            <img loading = "lazy" className = "noChange" src = "img/insta5.jpeg"/>
        </>
    );
}