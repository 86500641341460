import React from 'react';
import styled from 'styled-components';
import { Logo } from '../../Imgs/Logo.js';

export function Footer() {

    return (
        <Rodape>
            <Content id = "footer" className = "flex session">
                <ImgBotao className = "change notVisible flex container">
                    <Logo/>
                    <Botao target="_blank" href = "https://wa.me/557798125-7505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "change flex">
                        <h3 className = "bold">Fazer orçamento</h3>
                    </Botao>
                </ImgBotao>
            </Content>
            <CopyRight>
                <h3 className = 'change notVisible container'>
                    ©Copyright - Todos os direitos reservados - Acordes do Coração - 2024
                </h3>
            </CopyRight>
        </Rodape>
    );
}

const Rodape = styled.section`
    margin-top: -200px;
`;

const Content = styled.div`
    background-color: #0E1118;
    padding: 10px 0px 10px 0px;
    height: 260px;
    align-items: end;
`;

const ImgBotao = styled.div`
    img{
        width: 150px;
    }
    a:hover{
        background-color: #9A7240;
        color: white;
    }
    justify-content: space-between;
`;


const CopyRight = styled.div`
    background-color: #0E1118;
    h3{
        color: white;
    }
    padding: 10px 0px 10px 0px;
    margin-top: 1px;
`;

const Botao = styled.a`
    width: 200px;
    padding: 15px 6px;
    background-color: #DFAF60;
    gap: 10px;
    justify-content: center;
`;