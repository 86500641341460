import React from 'react';

export function ImgInsta4(){

    return (

        <>
            <img loading = "lazy" className = "noChange" src = "img/insta4.jpg"/>
        </>
    );
}