import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { Moldura } from '../../Imgs/moldura.js';

export function BemVindos() {

    const navigate = useNavigate();

    return (
        <>
            <div id = "bem-vindos">
            </div>
            <Content className = "escritorioContentResponsivo container session change flex">
                <ImagemMoldura className = "escritorioImgMoldura">
                    <Moldura/>
                    <Imagem className = "escritorioImagem backgroundImage"></Imagem>
                </ImagemMoldura>
                <TituloTextoBotao className = "flex">
                    <div>
                        <h1>Bem-vindos!</h1>
                        <div className = "line"></div>
                    </div>
                    <div>
                        <p>
                            Seja bem-vindo ao site da agência Acordes do Coração, sua fonte de 
                            música e emoção para os momentos mais especiais da vida.
                        </p>
                        <br/>
                        <p>
                            Somos uma agência musical apaixonada por transformar eventos em experiências 
                            inesquecíveis, trazendo harmonia e amor através da música.
                        </p>
                        <br/>
                        <p>
                            Na Acordes do Coração, oferecemos serviços musicais personalizados para 
                            tornar o seu casamento verdadeiramente memorável. Desde cerimônias a 
                            recepções, com o objetivo de abranger cada momento com a nossa música.
                        </p>
                        <br/>
                        <p>
                            Explore nosso site para descobrir mais sobre nossos serviços e nossa equipe.
                        </p>
                        <br/>
                        <p>
                            "Acordes do coração - Conectando almas através da música!""                      </p>
                    </div>
                    <Botao className = "flex">
                        <a target="_blank" href = "https://wa.me/557798125-7505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "change botao">
                            <BotaoLinha>
                                <p className = "bold">Faça um orçamento</p>
                            </BotaoLinha>
                        </a>
                    </Botao>
                </TituloTextoBotao>
            </Content>
        </>
    )
};

const Content = styled.section`
    justify-content: space-between;
    gap: 100px;
`;

const ImagemMoldura = styled.div`
    img{
        margin-bottom: -474px;
        z-index: 1;
        position: relative;
        width: 100%;
    }
`;

const Imagem = styled.div`
    width: 390px;
    height: 470px;
    position: relative;
`;

const TituloTextoBotao = styled.div`
    flex-direction: column;
    justify-content: center;
    gap: 45px;
    p{
        text-align: justify;
    }
`;

const Botao = styled.div`
    justify-content: center;
`;

const BotaoLinha = styled.div`
    border: 2px solid #DFAF60;
    padding: 8px 80px;
`;

