import React from 'react';
import styled from 'styled-components';

export function Servicos() {

    return (
        <>
            <Content className = "principiosFundoResponsivo session" id = "servicos">
                <TituloCards className = "flex container">
                    <div>
                        <h1>Nossos Serviços</h1>
                        <div className = "line"></div>
                    </div>
                </TituloCards>
            </Content>
            <Cards className = "principiosCardsResponsivo container notVisible change">
                <Card className = "principiosCardResponsivo card1 backgroundImage">
                    <h2>
                        Cerimônias
                    </h2>
                    <br/>
                    <p>
                        Somos especializados em proporcionar uma trilha sonora emocionante e 
                        inesquecível para este momento tão especial em suas vidas. Oferecemos 
                        uma variedade de opções musicais, desde músicos solo até grupos completos, 
                        capazes de adaptar-se perfeitamente ao estilo e ambiente que vocês desejam 
                        criar. Nossa equipe está dedicada a tornar seu dia ainda mais inesquecível. 
                        Entre em contato conosco para acessar nosso portfólio e lista de repertório. 
                        Estamos à disposição para discutir suas preferências e necessidades específicas 
                        para garantir que a música da sua cerimônia seja exatamente como vocês sonharam.
                         Estamos ansiosos para fazer parte deste momento único em suas vidas.
                    </p>
                </Card>
                <Card className = "principiosCardResponsivo card2 backgroundImage">
                    <h2>
                        Recepção
                    </h2>
                    <br/>
                    <p>
                        Entendemos que a música desempenha um papel crucial para celebrar esse momento 
                        tão especial na vida dos casais. Nosso objetivo é proporcionar uma experiência 
                        musical que não apenas complementa, mas eleva a energia e a alegria de sua 
                        recepção. Trabalhamos de perto com vocês para entender suas preferências 
                        musicais e quaisquer solicitações especiais que possam ter. Nossa equipe 
                        está comprometida em garantir que cada momento da sua recepção seja acompanhado 
                        pela música perfeita, criando memórias duradouras para vocês e seus convidados. 
                        Entre em contato conosco para mais informações. Estamos aqui para tornar seu 
                        dia ainda mais especial através da música.
                    </p>
                </Card>
            </Cards>
        </>
    )
};

const Content = styled.section`
    width: 100%;
    height: 730px;
    background-color: #0E1118;
    clip-path: polygon(0% 100%, 0% 0%, 100% 16%, 100% 100%);
    background-color:black;
    h1{
        color: white;
    }
    margin-top: 100px;
`;

const TituloCards = styled.div`
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Cards = styled.div`
    gap: 50px;
    margin-top: -500px;
    display: flex;
`;

const Card = styled.div`
    h2, p{
        color: white;
    }
    width: 49%;
    padding: 50px;
    z-index: 1;
    text-align: center;
`;

