import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/Header/Header.js';
import { Footer } from '../../components/Footer/Footer.js';
import { BemVindos } from './BemVindos.js';
import { Servicos } from './Servicos.js';
import { Equipe } from './Equipe.js';
import { Publicacoes } from './Publicacoes.js';
import { Orcamento } from './Orcamento.js';

export default function HomePage() {

    const activeScroll = () => {
        document.querySelectorAll('.change').forEach((div, index) => {
            if(!div.classList.contains("noChange")){
                if(div.getBoundingClientRect().top < window.innerHeight) {
                    div.classList.remove("notVisible");
                }
            }
        })
    }
    window.addEventListener('scroll', activeScroll);

    useEffect(() => {
        document.querySelectorAll('.change').forEach((div, index) => {
            if(!div.classList.contains("noChange")){
                if(div.getBoundingClientRect().top < window.innerHeight) {
                    div.classList.remove("notVisible");
                }
            }
        })
      }, [])

    return (
        <>
            <Header/>
            <BemVindos/>
            <Servicos/>
            <Equipe/>
            <Publicacoes/>
            <Orcamento/>
            <Wpp target="_blank" href = "https://wa.me/5577981257505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "wppBotao flex">
                <i className = "fa fa-whatsapp fa-3x" aria-hidden="true"></i>
            </Wpp>
            <Footer/>
        </>
    )
};

const Wpp = styled.a`
    background-color: #00AA01;
    width: 90px;
    height: 90px;
    border-radius: 100px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0px 10px 10px 0px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.55);
    i{
        color: white;
    }
    z-index: 2;
`;