import React from 'react';

export function ImgInsta6(){

    return (

        <>
            <img loading = "lazy" className = "noChange" src = "img/insta6.jpg"/>
        </>
    );
}