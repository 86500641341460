import React from 'react';
import styled from 'styled-components';
import { ImgInsta1 } from '../../Imgs/imgInsta1.js';
import { ImgInsta2 } from '../../Imgs/imgInsta2.js';
import { ImgInsta3 } from '../../Imgs/imgInsta3.js';
import { ImgInsta4 } from '../../Imgs/imgInsta4.js';
import { ImgInsta5 } from '../../Imgs/imgInsta5.js';
import { ImgInsta6 } from '../../Imgs/imgInsta6.js';
import { ImgInsta7 } from '../../Imgs/imgInsta7.js';

export function Publicacoes() {

    return (
        <>
            <div id = "publicacoes"></div>
            <Content className = "publicacoesResponsivo flex container session change">
                <div>
                    <h1>O Instagram</h1>
                    <div className = "line"></div>
                </div>
                <h2>
                    Conheça a nossa página do instagram
                </h2>
                <a target="_blank" href = "https://www.instagram.com/acordesdocoracao.ac/" className = "notVisible change botao">
                    <BotaoLinha>
                        <h2>@acordesdocoracao.ac</h2>
                    </BotaoLinha>
                </a>
            </Content>
            <Publs target="_blank" href = "https://www.instagram.com/acordesdocoracao.ac/" className = "notVisible change intagramPublsResponsivo container flex">
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta2/>
                </Publ>
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta1/>
                </Publ>
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta3/>
                </Publ>
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta4/>
                </Publ>
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta5/>
                </Publ>
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta6/>
                </Publ>
            </Publs>
        </>
    )
};

const Content = styled.section`
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 85px;
`;

const BotaoLinha = styled.div`
    border: 2px solid #DFAF60;
    padding: 8px 30px;
    p{
        font-weight: bold;
    }
`;

const Publs = styled.a`
    margin-top: 50px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
`;

const Publ = styled.div`
    flex-direction: column;
    width: 30%;
    img{
        width: 100%;
    }
    h2{
        text-align: center;
    }
    text-align: justify;
    gap: 15px;
`;

