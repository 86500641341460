import React from 'react';
import styled from 'styled-components';
import { Moldura } from '../../Imgs/moldura.js';

export function Equipe() {

    function saberMais(pessoa){
        const body = document.querySelector('body');
        const conteudo = document.querySelectorAll('.saiba-mais-conteudo');
        body.classList.add("noScroll");
        conteudo[pessoa].classList.remove("hidden");
    }

    function fecharSaberMais(pessoa){
        const body = document.querySelector('body');
        const conteudo = document.querySelectorAll('.saiba-mais-conteudo');
        body.classList.remove("noScroll");
        conteudo[pessoa].classList.add("hidden");
    }

    return (
        <>
            <div id = "equipe"></div>
            <div className = "session">
                <div onClick = {() => fecharSaberMais(0)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa1"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Ana Clara Domingues Pereira
                            </h1>
                            <br/>
                            <p>
                                Musicista a mais de 15 anos, iniciou sua trajetória como clarinetista 
                                e depois descobriu sua paixão pelo canto. possui uma vasta experiência 
                                em performances de cerimônias de casamento e eventos religiosos. Sua 
                                voz suave e sua presença calorosa elevam cada celebração, tornando-a 
                                a escolha ideal para eventos que exigem sensibilidade e classe musical. 
                                Oferece não apenas habilidades técnicas, mas também um compromisso 
                                apaixonado em proporcionar experiências musicais de alto nível para 
                                nossos clientes.
                            </p>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577981257505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "change flex">
                                <h3 className = "bold">Fazer orçamento!</h3>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <div onClick = {() => fecharSaberMais(1)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa2"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Samuel Brandão Santiago Lessa
                            </h1>
                            <br/>
                            <p>
                                Musicista a mais de 10 anos, possui habilidades tanto no teclado quanto 
                                no violão e baixo, além de ser um talentoso cantor.  Sua experiência 
                                inclui cerimônias matrimoniais e eventos religiosos, onde sua música 
                                tem o poder de criar momentos emocionantes e inesquecíveis. Não apenas 
                                domina seu instrumento com maestria, mas possui versatilidade, 
                                navegando por uma ampla gama de estilos musicais. 
                            </p>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577981257505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "change flex">
                                <h3 className = "bold">Fazer orçamento</h3>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <div onClick = {() => fecharSaberMais(2)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa3"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Ernane Domingues Pereira
                            </h1>
                            <br/>
                            <p>
                                Com uma carreira de mais de 20 anos dedicada à música, traz à vida 
                                melodias que capturam a essência única de cada momento especial. 
                                Seu saxofone emite harmonias suaves e emotivas, criando uma atmosfera 
                                de romance e celebração que ressoa com todos os presentes. Compartilha 
                                seu conhecimento como professor de música, sendo o atual regente da 
                                filarmônica 6 de outubro.
                            </p>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577981257505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "change flex">
                                <h3 className = "bold">Fazer orçamento</h3>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <div onClick = {() => fecharSaberMais(3)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa4"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Igor Guilherme da Rocha Cunha
                            </h1>
                            <br/>
                            <p>
                                Possui uma história de mais de 15 anos de dedicação e paixão pelo 
                                violão e pela arte de cantar. Sua voz suave e melodiosa, combinada 
                                com os acordes inspiradores do violão, proporciona uma trilha sonora 
                                emocionante para cada momento especial da cerimônia.  Ele traz não 
                                apenas técnica apurada, mas também uma profunda sensibilidade musical 
                                adquirida ao longo de toda a sua trajetória.
                            </p>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577981257505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "change flex">
                                <h3 className = "bold">Fazer orçamento</h3>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <div onClick = {() => fecharSaberMais(4)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa5"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Mateus Pereira Santana
                            </h1>
                            <br/>
                            <p>
                                Talentoso percussionista com uma trajetória de mais de 15 anos na 
                                música, preparado para criar atmosferas emocionantes para eventos 
                                especiais como casamentos. Com grande experiência em cerimônias, 
                                compreende a importância de cada momento e a necessidade de criar 
                                um ambiente harmonioso e envolvente. Além de suas habilidades 
                                musicais excepcionais, é reconhecido por seu profissionalismo e 
                                dedicação.
                            </p>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577981257505?text=Ol%C3%A1!%20Gostaria%20de%20um%20orçamento." className = "change flex">
                                <h3 className = "bold">Fazer orçamento</h3>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <Titulo className = "flex session change">
                    <div>
                        <h1>Equipe</h1>
                        <div className = "line"></div>
                    </div>
                </Titulo>
                <Content className = "equipeFundoResponsivo">
                </Content>
                <FundoMoldura className = "equipeFundoMolduraResonsivo flex container">
                    <div className = "notVisible change">
                        <Fundo className = "backgroundImage2 pessoa1"></Fundo>
                        <Moldura></Moldura>
                        <TextoBotao className = "flex">
                            <div>
                                <h2>Ana Clara Domingues</h2>
                                <p>Cantora</p>
                            </div>
                            <button onClick = {() => saberMais(0)} className = "change botao3">
                                <h3 className = "bold">Saiba mais</h3>
                            </button>
                        </TextoBotao>
                    </div>
                    <div className = "notVisible change">
                        <Fundo className = "backgroundImage2 pessoa2"></Fundo>
                        <Moldura></Moldura>
                        <TextoBotao className = "flex">
                            <div>
                                <h2>Samuel Brandão</h2>
                                <p>Tecladista, violonista e cantor</p>
                            </div>
                            <button onClick = {() => saberMais(1)} className = "change botao3">
                                <h3 className = "bold">Saiba mais</h3>
                            </button>
                        </TextoBotao>
                    </div>
                    <div className = "notVisible change">
                        <Fundo className = "backgroundImage2 pessoa3"></Fundo>
                        <Moldura></Moldura>
                        <TextoBotao className = "flex">
                            <div>
                                <h2>Ernane Domingues</h2>
                                <p>Saxofonista</p>
                            </div>
                            <button onClick = {() => saberMais(2)} className = "change botao3">
                                <h3 className = "bold">Saiba mais</h3>
                            </button>
                        </TextoBotao>
                    </div>
                    <div className = "notVisible change">
                        <Fundo className = "backgroundImage2 pessoa4"></Fundo>
                        <Moldura></Moldura>
                        <TextoBotao className = "flex">
                            <div>
                                <h2>Igor Cunha</h2>
                                <p>Violonista e cantor</p>
                            </div>
                            <button onClick = {() => saberMais(3)} className = "change botao3">
                                <h3 className = "bold">Saiba mais</h3>
                            </button>
                        </TextoBotao>
                    </div>
                    <div className = "notVisible change">
                        <Fundo className = "backgroundImage2 pessoa5"></Fundo>
                        <Moldura></Moldura>
                        <TextoBotao className = "flex">
                            <div>
                                <h2>Mateus Santana</h2>
                                <p>Baterista</p>
                            </div>
                            <button onClick = {() => saberMais(4)} className = "change botao3">
                                <h3 className = "bold">Saiba mais</h3>
                            </button>
                        </TextoBotao>
                    </div>
                </FundoMoldura>
                
            </div>
        </>
    )
};

const FundoMoldura = styled.div`
    img{
        width: 350px;
        z-index: 2;
        position: relative;
        margin-top: -340px;
    }
    z-index: 1;
    position: relative;
    margin-top: 50px;
    justify-content: center;
    padding: 0px 50px;
    gap: 100px;
    flex-wrap: wrap;
    margin-top: -1800px;
`;

const Fundo = styled.div`
    height: 343px;
    width: auto;
    margin-bottom: -8px;
`;

const TextoBotao = styled.div`
    background-color: #8e282c;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -5px;
    padding: 10px 10px 20px 10px;
    h2{
        font-weight: bold;
        color: white;
    }
    p{
        color: white;
    }
    h3{
        color: black;
    }
    text-align: center;
    gap: 10px;
    width: 350px;
    height: 170px;
`;

const Content = styled.div`
    margin-top: 100px;
    width: 100%;
    height: 1800px;
    padding: 0px 0px 70px 0px;
    background-color: #0E1118;
    clip-path: polygon(0 6%, 100% 0%, 100% 100%, 0% 100%);
    background-color:black;
    h1{
        color: white;
    }
    flex-direction: column;
    justify-content: flex-end;
    img{
        width: 350px;
    }
`;

const Titulo = styled.div`
    flex-direction: column;
`;

const SaibaMais = styled.div`
    width: 960px;
    height: 750px;
    padding: 30px;
    align-items: center;
    justify-content: space-between;
    img{
        margin-top: -365px;
        width: 380px;
    }
    h1, p{
        color: white
    }
    a:hover{
        background-color: #9A7240;
        color: white;
    }
    a{
        width: 300px;
        padding: 15px 6px;
        background-color: #DFAF60;
        gap: 10px;
        justify-content: center;
    }
    gap: 60px;
`;

const ImgSaibaMais = styled.div`
    height: 373px;
    width: auto;
    margin-bottom: -8px;
`;

const SaibaMaisTexto = styled.div`
    flex-direction: column;
    text-align: center;
    li{
        list-style-type: disc;
    }
    ul{
        text-align: left;
    }
    li::marker {
        color: white;
      }
`;



