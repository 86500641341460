import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";

export function Orcamento() {

    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {console.log(data)};

    return (
        <>
            <div id = "orcamento"></div>
            <Content className = "container orcamentoResponsivo session" id = "atuacao">
                <Fundo className = "notVisible change flex">
                    <div>
                        <h1>Faça seu orçamento!</h1>
                        <div className = "line2"></div>
                    </div>
                    <Textos className = "notVisible change contatoTextosResponsivo">
                        <FundoTexto className = "contatoFundoTextoResponsivo">
                            <br/>
                            <br/>
                            <p>
                                Deixe-nos saber mais sobre o seu evento e suas preferências 
                                musicais, nossa equipe trabalhará para criar uma proposta que 
                                atenda às suas necessidades e expectativas. 
                            </p>
                            <br/>
                            <p>
                                Nossos orçamentos abrangem uma variedade de pacotes musicais que 
                                podem incluir uma combinação de instrumentos como violão, teclado, 
                                baixo, guitarra, saxofone e bateria/cajon.
                            </p>
                            <br/>
                            <p>
                                Temos a combinação perfeita de músicos e instrumentos para tornar o seu 
                                dia inesquecível. Entre em contato conosco para explorar nossos pacotes.
                            </p>
                        </FundoTexto>
                        <TextForm className = "contatoTextFormResponsivo">
                            <Texto>
                                <h2>
                                    Entre em contato conosco
                                </h2>
                                <IconeTexto className = "contatoIconeTextoResponsivo flex">
                                    <i className = "fa fa-whatsapp fa-2x" aria-hidden="true"></i>
                                    <div>
                                        <p>
                                            Whatsapp
                                        </p>
                                        <p>
                                            +55 (77) 98125-7505
                                        </p>
                                    </div>
                                </IconeTexto>
                                <IconeTexto className = "contatoIconeTextoResponsivo flex">
                                    <i className = "fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                                    <div>
                                        <p>
                                            Email
                                        </p>
                                        <p>
                                            acordesdocoracao.contato@gmail.com
                                        </p>
                                    </div>
                                </IconeTexto>
                                <IconeTexto className = "contatoIconeTextoResponsivo flex">
                                    <i className = "fa fa-instagram fa-2x" aria-hidden="true"></i>
                                    <div>
                                        <p>
                                            Instagram
                                        </p>
                                        <p>
                                            @acordesdocoracao.ac
                                        </p>
                                    </div>
                                </IconeTexto>
                            </Texto>
                        </TextForm>
                    </Textos>
                </Fundo>
            </Content>
        </>
    )
};

const Content = styled.section`
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    z-index: 1;
    position: relative;
`;

const Fundo = styled.div`
    background-color: #DFAF60;
    justify-content: center;
    padding: 50px 50px;
    flex-direction: column;
`;

const FundoTexto = styled.div`
    background-color: #DFAF60;
    justify-content: center;
    flex-direction: column;
    width: 49%;
    text-align: justify;
    padding: 0px 15px;
`;

const Textos = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
`;

const TextForm = styled.div`
    background-color: #DFAF60;
    margin-top: 50px;
`;

const Texto = styled.div`
    width: 100%;
`;

const IconeTexto = styled.div`
    padding: 15px 6px;
    background-color: #DFAF60;
    gap: 10px;
    align-items: center;
    i{
        width: 50px;
    }
`;