import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import React from 'react';
import UserContext from "./Providers/UserContext";
import HomePage from './pages/HomePage/HomePage.js';
import './styles/reset.css';
import './styles/style.css';

function App() {

  const [instagramImages, setInstagramImages] = useState([]);

  return (

    <UserContext.Provider value={{ instagramImages, setInstagramImages }} >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
