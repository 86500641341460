import React from 'react';

export function Logo(){

    return (

        <>
            <img className = "noChange" src = "img/Logo.png"/>
        </>
    );
}